import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// material ui imports
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { Box, Button, IconButton, Snackbar, Typography } from '@material-ui/core';

// base components
import { StateProvider } from './store';
import theme from './theme';

// general components
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Resources from './pages/Resources';
import Product from './pages/Product';
import Contacts from './pages/Contacts';
import Company from './pages/Company';
import CaseStudies from './pages/CaseStudies';
import Education from './pages/Education';
import Success from './components/Success';
import Cancel from './components/Cancel';
import Newsroom from './pages/Newsroom';
import Raps from './pages/Raps';
import Webinar from './pages/Webinar';
import { Close } from '@material-ui/icons';
import Consultancy from './pages/Consultancy';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const App = () => {

  const [alertMessage, setAlertMessage] = useState(false);

  const handleClose = () => {
    localStorage.setItem('vivproRecon2024CookieMessage', 'false');
    setAlertMessage(false);
  };

  useEffect(() => {
    // const localAlertMessage = localStorage.getItem(
    //   "vivproRecon2024CookieMessage"
    // );
    // if (localAlertMessage === "false") {
    //   setAlertMessage(false);
    // } else {
    //   setAlertMessage(true);
    //   localStorage.setItem("vivproRecon2024CookieMessage", "true");
    // }
    const firebaseConfig = {
      apiKey: "AIzaSyAphgFKlW79TAVuw2SHdswRABf-f0ggpzk",
      authDomain: "vivpro-web.firebaseapp.com",
      projectId: "vivpro-web",
      storageBucket: "vivpro-web.appspot.com",
      messagingSenderId: "49101756362",
      appId: "1:49101756362:web:9ae880e12bd794e8b46133",
      measurementId: "G-BNWNBBCREF"
    }
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);

  }, []);
  return (<ThemeProvider theme={theme}>
      <StateProvider>
        <Router>
          <Navbar />
          <Box>
            <Switch>
              {/* add routes here */}
              <Route key={1} path="/" exact={true} component={Home}></Route>
              <Route
                key={2}
                path="/services"
                exact={true}
                component={Consultancy}
              ></Route>
              <Route
                key={3}
                path="/resources"
                exact={true}
                component={Resources}
              ></Route>
              <Route
                key={4}
                path="/products"
                exact={true}
                component={Product}
              ></Route>
              <Route
                key={4}
                path="/contact"
                exact={true}
                component={Contacts}
              ></Route>
              <Route
                key={5}
                path="/company"
                exact={true}
                component={Company}
              ></Route>
              <Route
                key={6}
                path="/resources/casestudies/:id"
                exact={true}
                component={CaseStudies}
              ></Route>
              <Route
                key={7}
                path="/resources/newsroom/:id"
                exact={true}
                component={Newsroom}
              ></Route>
              <Route
                key={8}
                path="/education"
                exact={true}
                component={Education}
              ></Route>
              <Route
                key={9}
                path="/success/:sessionId"
                exact={true}
                component={Success}
              ></Route>
              <Route
                key={10}
                path="/cancel"
                exact={true}
                component={Cancel}
              ></Route>
              <Route
                key={11}
                path="/raps2021"
                exact={true}
                component={Raps}
              ></Route>
              <Route
                key={7}
                path="/resources/webinar/:id"
                exact={true}
                component={Webinar}
              ></Route>
            </Switch>
          </Box>
          <Footer />
          <Snackbar
            open={alertMessage}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            onClose={handleClose}
            sx={{
              left: 'auto', width: 500, '&.MuiSnackbar-root': {
                color: '#fff'
              }, '& .MuiSnackbarContent-action': {
                position: 'absolute !important', top: `10px !important`, right: 10
              }
            }}
            message={<Box style={{ textAlign: 'left' }} padding={3}>
              <Typography variant="h5" sx={{ color: '#F2C94C' }}>
                <b>Introducing RE-CON 2024</b>
              </Typography>
              <Typography variant="body1" marginTop={2}>
                Register for annual conference and hands-on workshop on Jan
                22-23, 2024 at Baltimore, MD
              </Typography>
              <Button
                mt={2}
                variant="contained"
                onClick={() => {
                  window.open('https://varsity.vivpro.ai/recon', '_blank');
                  handleClose();
                }}
              >
                View Details
              </Button>
            </Box>}
            action={<IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>}
          />
        </Router>
      </StateProvider>
    </ThemeProvider>);
};

export default App;
